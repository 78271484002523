import React,{useEffect, useLayoutEffect, useState} from "react"
import offerImg from "./../../assets/images.jpg"

const OfferModal = () => {
    const [show, setShow] = useState(false)

   
    useEffect(()=>{
        setShow(true)
    },[])

    return (
      <>
        <div className="flex justify-end z-50 relative">
          <div className="bg-gray-950 rounded-s-2xl w-max py-1 px-3 fixed">
            {/* <span className="text-center text-white font-semibold">Book Table</span> */}
            {
              show &&
              <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full "
              >
                <div className="relative py-4 w-full max-sm:max-w-sm  sm:max-w-2xl max-h-full">
                  {/* Modal content */}
                  <div className="relative bg-white rounded-lg shadow-[0px_0px_8px_1px_#757575] overflow-hidden ">
                    {/* Modal header */}
                    <div className="absolute flex items-center justify-between px-4 md:px-4 py-2 md:py-2 rounded-t dark:border-gray-600">
                      {/* <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Book Table
                      </h3> */}
                      <button
                        type="button"
                        className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        data-modal-hide="authentication-modal"
                        onClick={() => setShow(false)}
                      >
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    {/* Modal body */}
                    <div className="pt-0">
                      <div className="w-full z-50">
                        <img src={offerImg} className="w-full" />
  
                      </div>
                     </div>
                  </div>
                </div>
              </div>
  
            }
  
          </div>
        </div>
      </>
    )
  }
 
  
 export default OfferModal;