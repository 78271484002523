import React, { useContext } from "react";
import { CartContext } from "../../context/cartContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";

const Cart = () => {
  const { totalItems } = useContext(CartContext);
  return (
    <div className="bg-gray-100 py-8 pb-10">
      <div className="container mx-auto px-4">
        {totalItems > 0 ? (
          <>
            <h1 className="text-2xl font-semibold mb-4">Shopping Cart</h1>
            <div className="flex flex-col md:flex-row gap-4">
              <CartList />
              <CartSummary />
            </div>
          </>
        ) : (
          <div className="bg-gray-100 w-full mt-32 justify-center">
            <div>
              <h2 className="font-extrabold text-center">
                No items added.
                <br />
                <Link
                  to="/"
                  className="font-semibold w-full m-auto text-center text-sm max-sm:text-xs text-blue-700"
                >
                  back to home
                </Link>
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CartList = () => {
  const { add, sub, data } = useContext(CartContext);
  const inCart = data.filter((e) => e.count > 0);
  return (
    <div className="md:w-3/6 lg:w-3/4 sm:w-full">
      {inCart.map((item, i) => (
        <div className="bg-white rounded-lg shadow-md p-5 mb-4" key={i}>
          <div className="w-full flex">
            <div className="py-1">
              <div className="flex items-center">
                <img
                  className="w-2/5 max-w-48 h-auto mr-4"
                  src={require(`../../assets/${item.img}`)}
                  alt="Product image"
                />
                <div>
                  <span className="font-semibold text-start text-lg">
                    {item.name}
                  </span>
                  <span className="text-sm font-semibold flex">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      style={{ marginTop: "6px", marginRight: "5px" }}
                      fontSize="12px"
                    />{" "}
                    {item.price}
                  </span>
                  <div className="text-sm max-h-16 overflow-hidden">
                    {item.desc}
                  </div>
                </div>
              </div>
            </div>

            <div className="py-2 items-center flex">
              <div className="flex items-center">
                <button
                  className="border rounded-md py-1 px-3 mr-1"
                  onClick={() => sub(item.id)}
                >
                  -
                </button>
                <span className="text-center w-6">{item.count}</span>
                <button
                  className="border rounded-md py-1 px-3 ml-1"
                  onClick={() => add(item.id)}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const CartSummary = () => {
  return (
    <div className="md:w-3/6 lg:w-3/12">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-lg font-semibold mb-4">Summary</h2>
        <div className="flex justify-between mb-2">
          <span>Subtotal</span>
          <span>$19.99</span>
        </div>
        <div className="flex justify-between mb-2">
          <span>Taxes</span>
          <span>$1.99</span>
        </div>
        <div className="flex justify-between mb-2">
          <span>Shipping</span>
          <span>$0.00</span>
        </div>
        <hr className="my-2" />
        <div className="flex justify-between mb-2">
          <span className="font-semibold">Total</span>
          <span className="font-semibold">$21.98</span>
        </div>
        <button className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4 w-full">
          Checkout
        </button>
      </div>
    </div>
  );
};

export default Cart;
