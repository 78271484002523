import React, { useState } from "react";
import { GiFoodTruck } from "react-icons/gi";
import Gif from "../../assets/mobile_bike.gif";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaLocationArrow,
  FaMobileAlt,
} from "react-icons/fa";

import footerLogo from "../../assets/logo.jpg";
import Slider from "react-slick";

const Footer = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-950 shadow-[0px_-2px_17px_1px_#cbd5e0]">
      <section className="bg-gray-100 max-w-[1200px] mx-auto">
        <div className=" bg-gray-1 grid md:grid-cols-2 py-5 ">
          <div className=" bg-gray-1 py-8 px-4 w-full">
            <h1 className="sm:text-3xl text-xl font-bold sm:text-left text-justify mb-3 flex items-center gap-3">
              <img src={footerLogo} alt="Logo" className="max-w-[50px]" />
              Fly-High Cafe
            </h1>
            <p className="text-sm">
              Behind CCD Caffee, Sangli-Miraj Road, Miraj 416410 Dist- Sangli
              [M.H.]{" "}
            </p>
            <br />
            <div className="flex text-sm items-center gap-3">
              <FaLocationArrow />
              <p>Connect Us</p>
            </div>
            <div className="flex text-sm items-center gap-3 mt-1">
              <FaMobileAlt />
              <p>+91 95957 17106</p>
            </div>
            {/* Social Handle */}
            {/* <div className="flex items-center gap-3 mt-6">
              <a href="#">
                <FaInstagram className="text-3xl" />
              </a>
              <a href="#">
                <FaFacebook className="text-3xl" />
              </a>
              <a href="#">
                <FaLinkedin className="text-3xl" />
              </a>
            </div> */}
          </div>
          <div className="py-8 px-4 w-full max-w-sm m-auto">
            <div data-aos="zoom-in" data-aos-duration="300">
              <img
                src={Gif}
                alt="mobile bike"
                className="w-full sm:max-w block rounded-md mx-auto mix-blend-multiply dark:mix-blend-difference"
              />
            </div>

            {/* <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Important Links
                </h1>
                <ul className={`flex flex-col gap-3`}>
                  <li className="cursor-pointer">Home</li>
                  <li className="cursor-pointer">About</li>
                  <li className="cursor-pointer">Services</li>
                  <li className="cursor-pointer">Login</li>
                </ul>
              </div> */}

            {/* <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Links
                </h1>
                <ul className="flex flex-col gap-3">
                  <li className="cursor-pointer">Home</li>
                  <li className="cursor-pointer">About</li>
                  <li className="cursor-pointer">Services</li>
                  <li className="cursor-pointer">Login</li>
                </ul>
              </div> */}

            {/* </div> */}

            {/* <div className="">
              <div className="py-8 px-4 ">
                <h1 className="sm:text-xl text-xl font-bold sm:text-left text-justify mb-3">
                  Links
                </h1>
                {/* <ul className="list-disc list-inside"> */}
            {/* <ul className="flex flex-col gap-3">
                  <li className="cursor-pointer">Home</li>
                  <li className="cursor-pointer">About</li>
                  <li className="cursor-pointer">Services</li>
                  <li className="cursor-pointer">Login</li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <div>
        <div className="text-center py-5 border-t-2 border-gray-300/50 bg-slate-400">
          @ 2024 All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
