import { createContext, useState } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([
    {
      id: 1,
      count: 0,
      img: "62.jpg",
      name: "Burger",
      price: "19.99",
      desc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus quia, nulla! Maiores et perferendis eaque,
            exercitationem praesentium nihil.`,
    },
    {
      id: 2,
      count: 0,
      img: "62.jpg",
      name: "Burger",
      price: "19.99",
      desc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus quia, nulla! Maiores et perferendis eaque,
            exercitationem praesentium nihil.`,
    },
    {
      id: 3,
      count: 0,
      img: "62.jpg",
      name: "Burger",
      price: "19.99",
      desc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus quia, nulla! Maiores et perferendis eaque,
            exercitationem praesentium nihil.`,
    },
    {
      id: 4,
      count: 0,
      img: "62.jpg",
      name: "Burger",
      price: "19.99",
      desc: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Voluptatibus quia, nulla! Maiores et perferendis eaque,
            exercitationem praesentium nihil.`,
    },
  ]);

  const add = (id) => {
    let value = data.map((el) =>
      el.id === id ? { ...el, count: 1 + parseInt(el.count) } : el
    );
    setData(value);
    let ans = value.filter((el) =>
      el.count > 0 ? setTotalItems(totalItems + 1) : null
    );
    console.log(value, ans, totalItems);
  };

  const sub = (id) => {
    let value = data.map((el) =>
      el.id === id ? { ...el, count: parseInt(el.count) - 1 } : el
    );
    setData(value);
    let ans = value.filter((el) =>
      totalItems > 0 ? setTotalItems(totalItems - 1) : setTotalItems(0)
    );
    console.log(value, ans, totalItems);
  };

  return (
    <CartContext.Provider value={{ add, sub, data, totalItems }}>
      {children}
    </CartContext.Provider>
  );
};
