import React, { useContext } from "react";
import Logo from "../../assets/logo.jpg";
import { FaCartShopping } from "react-icons/fa6";
import DarkMode from "./DarkMode";
import { Link } from "react-router-dom";
import { CartContext } from "../../context/cartContext";

const Menu = [
  {
    id: 1,
    name: "Home",
    link: "/#",
  },
  {
    id: 2,
    name: "Services",
    link: "/#services",
  },
  {
    id: 3,
    name: "About",
    link: "/#about",
  },
];
const Navbar = () => {
  const { add, sub, data, totalItems } = useContext(CartContext);

  return (
    <>
      <div className="shadow-md bg-white dark:bg-gray-900 dark:text-white duration-200">
        <div className="container py-3 sm:py-0">
          <div className="flex justify-between items-center">
            <div>
              <Link
                to="/"
                className="font-bold text-2xl sm:text-3xl flex justify-around mt-1 gap-2"
              >
                <img src={Logo} alt="Logo" className="w-20 my-2" />
                <p className="mt-4">Fly-High Cafe</p>
              </Link>
            </div>
            <div className="flex justify-between items-center gap-4">
              {/* <div>
                <DarkMode />
              </div> */}
              {/* <ul className="hidden sm:flex items-center gap-4">
                {Menu.map((menu) => (
                  <li key={menu.id}>
                    <a
                      href={menu.link}
                      className="inline-block py-4 px-4 hover:text-yellow-500"
                    >
                      {menu.name}
                    </a>
                  </li>
                ))}
              </ul> */}

              {/* <button className="bg-gradient-to-r from-primary to-secondary hover:scale-105 duration-200 text-white py-1 px-4 rounded-full flex items-center gap-3">
                <FaCartShopping className="text-xl text-white drop-shadow-sm cursor-pointer" />
                <span className="absolute top-0 right-0 left-auto w-5 h-5 end-full rounded-full">
                  0
                </span>
              </button>
              <button className="cart-button">
                <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" />
                <span className="cart-quantity"></span>
              </button> */}

              <Link to="/MyCart">
                <div class="flex justify-center items-center">
                  <div class="relative py-2">
                    {totalItems > 0 ? (
                      <div class="t-0 absolute left-3">
                        <p class="flex h-2 w-2 items-center justify-center rounded-full bg-red-500 p-3 text-xs text-white">
                          {totalItems}
                        </p>
                      </div>
                    ) : null}

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="file: mt-4 h-6 w-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
