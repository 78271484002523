import React from "react";
import Img from "../../assets/62.jpg";
import Img2 from "../../assets/276.jpg";
import Img3 from "../../assets/63.jpg";
import StarRatings from "react-star-ratings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
const ServicesData = [
  {
    id: 1,
    img: Img,
    name: "Pizza",
    description:
      "Avail great deals and offers on online pizza orders with Domino's everyday value offers, get 2 regular hand tossed pizzas @ Rs. 99 each.",
    price:"50.6",
    
    },
  {
    id: 2,
    img: Img2,
    name: "Chiken Grill",
    description:
      "moky Grilled Chicken Offers Up to 50% OFF.      Chicken Lebanese Grilled (Half) ; Minimum Order Quantity. 1 ; Full · Rs.340/- ",
      price:"102.0",
    },
  {
    id: 3,
    img: Img3,
    name: "Burger",
    description:
      "Double Patty Burger - On Orders Above Rs 229. Rs.30 Cashback on App/Website ",
      price:"99.0",
  },
];
const Services = () => {
  return (
    <>
      <span id="services"></span>
      <div className="py-10">
        <div className="container">
          <div className="text-center mb-20 max-w-[400px] mx-auto">
            <p className="text-m bg-clip-text text-lg text-transparent bg-gradient-to-r from-amber-600 to-amber-700 ">
              <h2>What is in</h2>
            </p>
            <h1 className="text-3xl font-bold">Your MIND? We offer!!</h1>
             <p lassName="text-3xl font-bold"><h1></h1></p>
            {/* <p className="text-xs text-gray-400">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Perspiciatis delectus architecto error nesciunt,
            </p> */}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-14 md:gap-5 place-items-center space-y-6">
            {ServicesData.map((item) => (
              <div
                data-aos="zoom-in"
                data-aos-duration="300"
                className="rounded-2xl bg-white dark:bg-gray-800 hover:bg-primary dark:hover:bg-primary hover:text-white relative shadow-xl duration-high group max-w-[300px]"
              >
                <div className="h-fit">
                  <img
                    src={item.img}
                    alt=""
                    className="max-w-[200px] block mx-auto transform -translate-y-14
                  group-hover:scale-105 group-hover:rotate-6 duration-300"
                  />
                </div>
                <div className="p-4 -mt-14 text-center">
                  <div className="w-full ">
                    <StarRatings
                      rating={4}
                      starRatedColor="yellow"
                      isSelectable={false}
                      starHoverColor="yellow"
                      // starSelectingHoverColor
                      starDimension="20px"
                      changeRating={() => {}}
                      numberOfStars={5}
                      name="rating"
                    />
                  </div>
                  <h1 className="text-xl font-bold">{item.name}</h1>
                  <p className="text-gray-500 group-hover:text-white duration-high text-sm line-clamp-2">
                    {item.description}
                  </p>
                  <div className="flex mt-4">
<div className="w-5/12 flex ml-1 justify-start">
<h3 className="text-lg text-center font-semibold"><FontAwesomeIcon icon={faIndianRupeeSign} style={{marginTop:'6px',marginRight:'5px'}} fontSize="15px" /> {item.price}</h3>
</div>
<div className="w-7/12 flex mr-1 justify-end">
  <button className="bg-red-500 text-white font-semibold font-system tracking-wide rounded-md py-1 px-2">Order Now</button>
</div>
                  </div>
                  
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
