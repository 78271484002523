import React, { useState } from "react";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import Services from "./components/Services/Services.jsx";
import Banner from "./components/Banner/Banner.jsx";
import AppStore from "./components/AppStore/AppStore.jsx";
import CoverBanner from "./components/CoverBanner/CoverBanner.jsx";
import Testimonial from "./components/Testimonial/Testimonial.jsx";
import Footer from "./components/Footer/Footer.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import BookATableImg from "../src/assets/book-a-table.jpg";
import Category from "./components/Category/Category.jsx";
import Menu from "./components/Menu/Menu.jsx";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import OfferModal from "./components/Offer/OfferModal.jsx";
import Cart from "./components/Cart/Cart.jsx";

const App = () => {
  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 500,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <BrowserRouter>
        <div className="bg-white dark:bg-gray-900 dark:text-white duration-200">
          <Navbar />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <div className="bg-white dark:bg-gray-900 dark:text-white duration-200">
                  <OfferModal />
                  <BookTable />
                  <Hero />
                  <Category />
                  <Services />
                  {/* <Banner /> */}
                  <CoverBanner />
                  {/* <AppStore /> */}
                  {/* <Testimonial /> */}
                </div>
              }
            ></Route>
            <Route exact path="/Menu" element={<Menu />}></Route>
            <Route exact path="/MyCart" element={<Cart />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </>
  );
};

const BookTable = () => {
  const [show, setShow] = useState();
  const [data, setData] = useState([
    {
      fn: "",
      ln: "",
      mobile: "",
      date: "",
      time: "",
      people: "",
      likeTo: "",
    },
  ]);
  const HandelSubmit = () => {
    // setData({fn,ln,date,time,people,likeTo})
    console.log(data);
  };
  const HandelChange = (event) => {
    const { name, value } = event.target;
    setData((prevFormData) => ({ ...prevFormData, [name]: value }));
    // console.log(data)
  };
  return (
    <>
      <div className="flex justify-end z-40 relative">
        <div className="bg-gray-950 rounded-s-2xl w-max py-1 px-3 fixed">
          {/* <span className="text-center text-white font-semibold">Book Table</span> */}

          {/* Modal toggle */}
          <button
            className="text-center text-white font-black font-bold text-xl"
            type="button"
            onClick={() => (show ? setShow(false) : setShow(true))}
          >
            Book Table
          </button>
          {/* Main modal */}
          {show && (
            <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
              <div className="relative py-4 w-full max-sm:max-w-sm  sm:max-w-2xl max-h-full">
                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow-[0px_0px_8px_1px_#757575]">
                  {/* Modal header */}
                  <div className="flex items-center justify-between px-4 md:px-4 py-2 md:py-2 rounded-t dark:border-gray-600">
                    {/* <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                      Book Table
                    </h3> */}
                    <button
                      type="button"
                      className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-hide="authentication-modal"
                      onClick={() => setShow(false)}
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <div className="p-4 md:p-5 pt-0 md:pt-0 flex space-x-3">
                    <div className="w-6/12 max-sm:hidden max-sm:w-0">
                      <img src={BookATableImg} />
                    </div>
                    <div className="w-6/12 max-sm:w-full ">
                      <form className="space-y-2" onSubmit={HandelSubmit}>
                        <div>
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="First Name"
                            required
                            value={data.fn}
                            name="firstName"
                            onChange={HandelChange}
                          />
                        </div>

                        <div>
                          <input
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Mobile"
                            required
                            value={data.mobile}
                            name="mobile"
                            onChange={HandelChange}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Date of Booking"
                            onFocus={(e) => (e.currentTarget.type = "date")}
                            onBlur={(e) => (e.currentTarget.type = "text")}
                            required
                            value={data.date}
                            name="date"
                            onChange={HandelChange}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Time of Booking"
                            onFocus={(e) => (e.currentTarget.type = "time")}
                            onBlur={(e) => (e.currentTarget.type = "text")}
                            required
                            value={data.time}
                            name="time"
                            onChange={HandelChange}
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="No. of People(s)"
                            required
                            value={data.people}
                            name="people"
                            onChange={HandelChange}
                          />
                        </div>
                        <div>
                          <textarea
                            rows={6}
                            placeholder="Love to have... (optional)"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            value={data.likeTo}
                            name="likeToHave"
                            onChange={HandelChange}
                          />
                        </div>

                        <button
                          type="submit"
                          className="w-full text-white bg-black -700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Book Table Now
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
