import React, { useState } from "react";
import MenuCard from "./MenuCard";
import MenuList from "./MenuCard";


const Menu=()=>{
    const [tab,setTab]=useState(1)
  return (
    <div className="py-6 px-4">
        <div className="flex border-b-2 border-gray-200">
            <div className="flex">
            <li className={`py-3 px-4  border-sky-600 hover:bg-gray-100 list-none cursor-pointer text-sm ${tab===1&&"border-b-2 bg-gray-100"}`} onClick={()=>setTab(1)}>Veg</li>
            <li className={`py-3 px-4  border-sky-600 hover:bg-gray-100 list-none cursor-pointer text-sm ${tab===2&&"border-b-2 bg-gray-100"}`} onClick={()=>setTab(2)}>Non-veg</li>
            <li className={`py-3 px-4  border-sky-600 hover:bg-gray-100 list-none cursor-pointer text-sm ${tab===3&&"border-b-2 bg-gray-100"}`} onClick={()=>setTab(3)}>Recommanded</li>
              </div>
        </div>
        <div className="mt-8">
              {
                tab===1&&<MenuList />
                
              ||tab===2&&<>
              <div class="max-w-sm w-full lg:max-w-full lg:flex">
  <div class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" style={{backgroundImage:"url('/img/card-left.jpg')"}} title="Woman holding a mug">
  </div>
  <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
    <div class="mb-8">
      <p class="text-sm text-gray-600 flex items-center">
        <svg class="fill-current text-gray-500 w-3 h-3 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
        </svg>
        Members only
      </p>
      <div class="text-gray-900 font-bold text-xl mb-2">Can coffee make you a better developer?</div>
      <p class="text-gray-700 text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.</p>
    </div>
    <div class="flex items-center">
      <img class="w-10 h-10 rounded-full mr-4" src="/img/jonathan.jpg" alt="Avatar of Jonathan Reinink"/>
      <div class="text-sm">
        <p class="text-gray-900 leading-none">Jonathan Reinink</p>
        <p class="text-gray-600">Aug 18</p>
      </div>
    </div>
  </div>
</div>
              </>
              ||tab===3&&<p>as3</p>
}
              </div>
    </div>
  );
}

export default Menu