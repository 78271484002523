import { useEffect, useState } from "react";
import CategoryImg1 from "../../assets/biryani_cover.jpg";
import CategoryImg2 from "../../assets/biryani.png";
import CategoryImg3 from "../../assets/biryani2.png";
import CategoryImg4 from "../../assets/biryani3.png";
import CategoryImg5 from "../../assets/biryani4.png";
import CategoryImg6 from "../../assets/biryani5.png";
import { Link } from "react-router-dom";

const Category = () => {
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    setCategoriesList([
      {
        img: CategoryImg1,
        name: "Biryani",
        link:"/Menu",
      },
      {
        img: CategoryImg2,
        name: "Pasta",
        link:"/Menu",
      },
      {
        img: CategoryImg3,
        name: "Pizza",
        link:"/Menu",
      },
      {
        img: CategoryImg4,
        name: "Burger",
        link:"/Menu",
      },
      {
        img: CategoryImg5,
        name: "Paneer",
        link:"/Menu",
      },
      {
        img: CategoryImg6,
        name: "Cake",
        link:"/Menu",
      },
    ]);
  }, []);
  return (
    <>
    <h1 className="text-3xl font-bold py-4 text-center max-sm:text-2xl">What would you like to have ?</h1>
      <div className="flex p-4 space-x-6 h-max flex-wrap justify-center">
      
        {categoriesList.map((item) => {
          return (
            <>
            <Link to={item.link} >
              <div className="p-2 text-center max-sm:p-1">
                <img
                  src={item.img}
                  alt={item.name}
                  className="rounded-2xl w-40 h-40 md:w-28 md:h-28 sm:w-24 sm:h-24 max-sm:w-20 max-sm:h-20"
                />

                <p className="text-rose-700 font-system text-inherit text-md mt-2 sm:mt-1 max-sm:mt-1">
                  {item.name}
                </p>
              </div>
              </Link>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Category;
