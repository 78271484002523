import { useContext, useState } from "react";
import img from "../../assets/276.jpg";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../../context/cartContext";

const MenuList = () => {
  const { data } = useContext(CartContext);

  return (
    <>
      <div className="flex flex-wrap gap-4 justify-center">
        {/* {data.map((item) => {
          console.log(item);
          return <MenuCard key={id} id={item.id} />;
        })} */}
        {data.map((item, i) => {
          return <MenuCard key={i} id={item.id} />;
        })}
      </div>
    </>
  );
};

const MenuCard = ({ id }) => {
  const { add, sub, data } = useContext(CartContext);
  const list = data.filter((e) => e.id === id)[0];
  const baseURL = "../../assets/";
  console.log(baseURL + "62.jpg");
  return (
    <>
      <div class="rounded overflow-hidden shadow-[0px_0px_9px_1px_#e2e8f0] w-72">
        <img
          class="w-full"
          src={require(`../../assets/${list.img}`)}
          alt="Sunset in the mountains"
        />
        <div class="px-4 pb-4 mt-2">
          <div class="font-bold text-lg mb-2">{list.name}</div>
          {/* <span className="text-gray-900 text-lg font-bold">{list.price}</span> */}
          <p class="text-gray-700 text-sm">{list.desc}</p>
        </div>
        <div class="px-4 pb-3 w-full flex columns-2">
          {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
    <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#winter</span> */}
          {/*  
 <button className="rounded-xl bg-yellow-500 hover:bg-yellow-600 py-1.5 px-3 font-bold text-white shadow-sm">
        Order Now
    </button>  */}
          <div className="w-full my-auto">
            <span className="text-lg font-semibold flex">
              <FontAwesomeIcon
                icon={faIndianRupeeSign}
                style={{ marginTop: "6px", marginRight: "5px" }}
                fontSize="14px"
              />{" "}
              {list.price}
            </span>
          </div>
          <div className="flex w-full ml-auto justify-end">
            {list.count > 0 ? (
              <>
                <button
                  onClick={() => add(id)}
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold shadow-sm rounded-xl py-1  w-3/12"
                >
                  +
                </button>
                <button className=" font-semibold ml-2 mr-2 shadow-sm rounded-xl py-1">
                  {list.count}
                </button>
                <button
                  onClick={() => sub(id)}
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold shadow-sm rounded-xl py-1  w-3/12"
                >
                  -
                </button>
              </>
            ) : (
              <button
                onClick={() => add(id)}
                className="bg-red-500 hover:bg-red-600 text-white font-semibold ml-2 mr-2 shadow-sm rounded-xl py-1 min-w-full "
              >
                Add
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuList;
